<template>
    <div class="page-content">
        <page-breadcrumb title="Activity Earn Management" class="mb-3" />
        <section class="py-1">
            <form-generator :schema="filter_schema" :model="filter" />
            <b-card>
                <data-table-ssr id="transfer-nft-management" ref="transfer_nft_management" :limit-base="9"
                    pagination
                    :get-list-fn="getList" :columns="table_field"
                    :to-edit-fn="openModal"
                />
            </b-card>
        </section>
        <modal ref="modal" />
    </div>
</template>
<script>
import service from "../service"
import Modal from "./_components/Modal.vue"
const status_options = [
    {text: "ACTIVE", value: 1},
    {text: "PENDING", value: 2}
]
const activity_options = [
    {text: "Stage", value: 1},
    {text: "Pronun", value: 2},
    {text: "Breaking", value: 3}
]
const table_field = [
  { label: 'User ID', field: 'user_id' },
  { label: 'Activity type', field: 'activity_type', type: 'array', input_type: 'select', options: activity_options },
  { label: 'Activity ID', field: 'activity_id' },
  { label: 'Score', field: 'score' },
  { label: 'Token earn', field: 'token_earn' },
  { label: 'Battery consumed', field: 'batery_consumed' },
  { label: 'Activated tutor', field: 'activated_tutor' },
  {
    label: 'Status', field: 'status', type: 'array', input_type: 'select', options: status_options,
  },
  { label: 'Occur Time', field: 'occur_time', input_type: 'datetime' },
];
const filter_schema = [
  { cols: 4, fields: [{ label: 'User ID', field: 'user_id' }] },
  { cols: 4, fields: [{ label: 'Activity type', field: 'activity_type', input_type: 'select', options: activity_options}] },
  { cols: 4, fields: [{ label: 'Activity ID', field: 'token_id'}] },
  { cols: 4, fields: [{ label: 'Status', field: 'status', input_type: 'select', options: status_options }] },
  { cols: 6, fields: [{ label: 'Occur Time', field: 'time_range', input_type:"datetime_range"}] }
];
export default {
    components:{
        Modal
    },
    data(){
        return {
            export_data: [],
            filter: {
                time_range:[
                    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                ]
            },
            table_field,
            filter_schema,
            statusObj:{
                1: "COMPLETED",
                2: "PENDING"
            },
            activityObj: {
                1:"Stage",
                2:"Pronun",
                3:"Breaking"
            }
        }
    },
     watch: {
        current_page() {
            this.getList();
        },
        filter: {
            handler() {
                if (this.filter.time_range.match(/to/i)){
                    this.$refs.transfer_nft_management.getList();
                }
            },
            deep: true,
        },
    },
    
    methods: {
        async getList({ limit, page, query }) {

            let list = [], total = 0;
            let response = await service.getList({ query: { ...query, ...this.filter }, page, limit });
            if (response.data.type === "DATA") {
                list = response.data.data.list;
                total = response.data.data.total;
            }
            return { list, total };
        },
        async openModal(data){
            this.$refs.modal.openDetailModal(data)
        }
    }
}
</script>