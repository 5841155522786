<template>
  <div v-if="isOpen">
    <b-modal
      centered
      title="Activity Earn Detail"
      v-model="isOpen"
      id="modal-lg"
      size="lg"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <b-row class="pb-2">
          <b-col md="2">
            <label for="">User id</label>
          </b-col>
          <b-col md="8">
            <b-input :value="getTitle(model.user_id)" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Activity total</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.activity_total" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Activity remain</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.activity_remain" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Activity type</label>
          </b-col>
          <b-col md="8">
            <b-input :value="activityObj[model.activity_type]" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Activity ID</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.activity_id" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Score</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.score" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Max score</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.max_score" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Token balance</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.token_balance" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Token earn</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.token_earn" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Battery</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.batery" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Battery consumed</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.batery_consumed" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Activated tutor</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.activated_tutor" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Tutor snapshot</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.tutor_snapshot" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Status</label>
          </b-col>
          <b-col md="8">
            <b-input :value="statusObj[model.status]" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Occur Date</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.occur_date | local_time_string" disabled />
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
        isOpen: false,
        isUpdate: false,
        statusObj:{
            1: "COMPLETED",
            2: "PENDING"
        },
        activityObj: {
            1:"Stage",
            2:"Pronun",
            3:"Breaking"
        }
    };
  },
  methods: {
    async openDetailModal(activity){
        this.model = activity;
        this.isOpen = true;
        this.isUpdate = true;
    },

    cancel(){
      this.isOpen = false;
      this.isUpdate = false;
      this.model = {};
    },

    getTitle(url) {
      return url ? url.slice(8) : ''
    },

    getBody(data) {
      if (typeof data === 'object') {
        const keys = Object.keys(data)
        if (keys.length === 1 && keys[0] === 'data') {
          data = JSON.stringify(JSON.parse(data.data))
          return data
        }
      }
      return JSON.stringify(data)
    }
  },
};
</script>
<style lang="scss" scoped>
  label {
    font-size: 16px;
    margin-top: 0.3rem;
    margin-bottom: 0;
  }
</style>
